import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Spinner from 'components/common/spinner';
import Collapsible from 'components/common/collapsible';
import SubjectMini from 'components/common/course/subject-mini';
import { Heading } from 'components/common/typography';
import RoutesGenerator, { Route } from 'services/router/generator';
import { UserAPI } from 'api';
import './styles.less';
import { Divider } from 'antd';

const Stage = (props: any) => {
  const { stage, courseId, history, superStage } = props;

  const subjects = stage.subjects.map((subs: any) => (
    <SubjectMini
      key={subs.id}
      subject={subs}
      actionBtn={{
        label: 'Explore',
        goTo: (subjectId: number) => {
          const path = RoutesGenerator(
            Route.Subject,
            { courseId: courseId, superStageId: superStage.id, subjectId: subjectId, skipSuperStage: superStage.skip_super_stage }
          )
          history.push(path)
        }
      }}
    />
  ));

  return (
    <div className="my-courses__stage">
      <Heading type={4}>
        {stage.title}
      </Heading>
      {subjects}
    </div>
  );
};

const SuperStage = (props: any) => {
  const { superStage, history, courseId, lastItem } = props;

  const stages = superStage.stages.map((stage: any, index: number) => (
    <Stage
      key={index}
      stage={stage}
      history={history}
      courseId={courseId}
      superStage={superStage} />
  ));

  if(superStage.skip_super_stage){
    return stages
  }

  return(
    <div>
      <div style={{marginBottom: '20px'}}>
        <Heading type={4}>{superStage.title}</Heading>
      </div>
      {stages}
      { !lastItem && <Divider></Divider>}
    </div>
  )
}

const Courses = (props: any) => {
  const { courses, history } = props;

  const items = courses.map((item: any) => {
    const superStages = item.super_stages.map((superStage: any, index: number) => (
      <SuperStage
        key={index}
        superStage={superStage}
        history={history}
        courseId={item.course_id}
        lastItem={ (item.super_stages.length - 1) == index }
      />
    ));

    return {
      title: item.course_title,
      body: superStages,
    };
  });

  return <Collapsible content={items} />;
};

const MyCourses = () => {
  const [courses, setCourses] = useState<any>(null);
  const [busy, setBusy] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const getMyCourses = async () => {
      try {
        const res = await UserAPI.getMyCourses();
        setCourses(res.content.purchased_items);
        setBusy(false);
      } catch (error) {
        setBusy(false);
        console.log(error);
      }
    };

    getMyCourses();
  }, []);

  if (busy) {
    return <Spinner />;
  }

  if (!courses) {
    return <>Looks like you don't have any courses yet.</>;
  }


  return(
    <>
      <Courses courses={courses.filter((course: any)=> course.archived == false)} history={history}/>
      <br />
      <br />
      <div className="heading ">
        <h4>Archived Courses</h4>
      </div>
      <Courses courses={courses.filter((course: any)=> course.archived == true)} history={history}/>
    </>
  )
};

export default MyCourses;
