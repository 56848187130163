const checkBatchExpiration = (batchEndDate: string): boolean => {
  const currentTimeInSL = new Date(
    new Intl.DateTimeFormat("en-US", {
      timeZone: "Asia/Colombo",
    }).format(new Date())
  );

  if(batchEndDate){
    const expDateInSL = new Date(batchEndDate + "T00:00");

    if(expDateInSL > currentTimeInSL){
      return false
    }
    else{
      return true
    }
  }
  else
    return true
}

export default checkBatchExpiration