import React, { useState, useEffect } from "react";
import { Divider } from "antd";
import { Element, scroller } from "react-scroll";
import { CourseAPI } from "api";
import TitleArea from "components/common/content/title";
import { Heading } from "components/common/typography";
import { Breadcrumb } from "components/common/breadcrumb/types";
import Container from "components/common/section-container";
import FSL from "components/common/full-screen-loader";
import landingBg from "assets/images/home/home-landing-bg.jpg";
import { VideoContainer } from "components/common/course";
import RoutesRegistry from "services/router/registry";
import { truncate } from "utils";
import "./index.less";
import CourseStage from "../stage";

const IntroSection = (props: any) => {
  const { title, description, image, video } = props;

  return (
    <Container className="intro-container">
      <VideoContainer
        imageUrl={image}
        videoUrl={''}
        showVideo={false}
        className="video-container"
      />
      <Heading type={3}>{title}</Heading>
      <Divider />
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Container>
  );
};

const getStageName = (id: string | null) => `stage${id}`;

const SuperStage = (props: any) => {
  const { match, location } = props;
  const [superStage, setSuperStage] = useState<any>(null);
  const [busy, setBusy] = useState(true);
  const { cid, id } = match.params;
  const urlParams = new URLSearchParams(location.search);
  const stageId = urlParams.get("stage");

  const crumbs: Breadcrumb[] = [
      RoutesRegistry.Courses,
      {
        title: RoutesRegistry.Courses.Course.title,
        path: `${RoutesRegistry.Courses.path}/${cid}`,
      },
      {
        title: RoutesRegistry.Courses.Course.SuperStage.title,
      },
    ];

  useEffect(() => {
    const getStages = async () => {
      try {
        const res = await CourseAPI.superStage(Number(cid), Number(id));
        setSuperStage(res.content);
        setBusy(false);

        if (stageId) {
          scroller.scrollTo(getStageName(stageId), {
            offset: -50,
          });
        }
      } catch (error) {
        setBusy(false);
        console.log(error);
      }
    };

    getStages();
  }, []);

  if (busy) {
    return <FSL />;
  }

  const stages = superStage.stages.map((stage: any) => (
    <Element name={getStageName(stageId)}>
      <CourseStage
        key={stage.id}
        id={superStage.id}
        stage={stage}
        courseTitle={superStage.title}
        skipSuperStage={false}
      />
    </Element>
  ));

  return (
    <>
      <TitleArea
        backgroundImg={landingBg}
        icon="charted-acc"
        breadcrumbs={crumbs}
        title={truncate(superStage.title, 35)}
      />
      <section className="section-container">
        <IntroSection
          title={superStage.title}
          description={superStage.description}
          image={superStage.cover_image.url}
        />

        <div className="stages-container">{stages}</div>
      </section>
    </>
  );
};

export default SuperStage;