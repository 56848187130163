import React, { useState, useEffect } from "react";
import { Col, Divider, Row } from "antd";
import { CourseAPI } from "api";
import TitleArea from "components/common/content/title";
import { Heading } from "components/common/typography";
import { Breadcrumb } from "components/common/breadcrumb/types";
import Container from "components/common/section-container";
import FSL from "components/common/full-screen-loader";
import landingBg from "assets/images/home/home-landing-bg.jpg";
import { VideoContainer, SuperStageMini } from "components/common/course";
import { truncate } from "utils";
import "./styles.less";
import { SuperStage as ISuperStage } from "interfaces/superStage";
import RoutesRegistry from "services/router/registry";
import { Element } from "react-scroll";
import CourseStage from "./stage";

const crumbs: Breadcrumb[] = [
  RoutesRegistry.Courses,
  {
    title: RoutesRegistry.Courses.Course.title,
  },
];

const IntroSection = (props: any) => {
  const { title, description, image, video } = props;

  return (
    <Container className="intro-container">
      <VideoContainer
        imageUrl={image}
        videoUrl={video.videoUrl}
        showVideo={video.showVideo}
        className="video-container"
      />
      <Heading type={3}>{title}</Heading>
      <Divider />
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Container>
  );
};

const Course = (props: any) => {
  const { match } = props;
  const [course, setCourse] = useState<any>(null);
  const [superStages, setSuperStages] = useState<ISuperStage[]>([])
  const [busy, setBusy] = useState(true);
  const { id } = match.params;
  let stages = [];

  useEffect(() => {
    const getCourses = async () => {
      try {
        const res = await CourseAPI.course(Number(id));
        setCourse(res.content);

        const superStageRes = await CourseAPI.superStages(Number(id));
        setSuperStages(superStageRes.content.super_stages as ISuperStage[]);

        setBusy(false);
      } catch (error) {
        setBusy(false);
        console.log(error);
      }
    };

    getCourses();    
  }, []);

  const getStageName = (id: string | null) => `stage${id}`;

  const superStagesList = superStages.map((superStage, _index) => {
    return(
      <>
        <SuperStageMini
          key={superStage.id}
          superStage={superStage}
        />
      </>
    )
  })

  if(course){
    stages = course.stages.map((stage: any) => (
      <Element name={getStageName(stage)}>
        <CourseStage
          key={stage.id}
          courseId={course.id}
          stage={stage}
          courseTitle={course.title}
          skipSuperStage={true}
        />
      </Element>
    ));
  }

  if (busy) {
    return <FSL />;
  }

  return (
    <>
      <TitleArea
        backgroundImg={landingBg}
        icon="charted-acc"
        breadcrumbs={crumbs}
        title={truncate(course.title, 35)}
      />
      <section className="section-container">
        {
          // skip super stage if logic
          (!busy && superStages.length == 1 && superStages[0].skip_super_stage == true) 
            ? 
              <div className="stages-container">{stages}</div>
            : 
              <div className="container">
                <div className="content-container super-stage-container">
                  <Row gutter={[5, 5]}>{superStagesList}</Row>
                </div>
              </div>
        }
      </section>
    </>
  );
};

export default Course;
