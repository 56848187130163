import React, { useState } from "react";
import "./superStageMini.less";
import { SuperStage as ISuperStage } from "interfaces/superStage";
import { Heading } from "components/common/typography";
import { Col, Row } from "antd";
import { Button } from "components/common/form";
import { useHistory, useParams } from 'react-router-dom';
import routeGenerator from "services/router/generator";
import { Rating } from 'components/common/course';
import Modal from "components/common/modal";

interface SuperStageProps {
  superStage: ISuperStage
}

const SuperStageMini = ({ superStage }: SuperStageProps) => {
  const { id } = useParams<any>();
  const history = useHistory();
  const superStagePath = routeGenerator(8, { courseId: id, id: superStage.id })
  const [showReviews, setShowReviews] = useState<boolean>(false);

  const navigateToSuperStage = () => {
    history.push(superStagePath)
  }

  const toggleReviews = () => {}

  const reviewsList = superStage.reviews && superStage.reviews.map((review) => {
    return(
      <div className="review-item" key={review.id}>
        <div className="review-item-container">
          <p>{review.message}</p>
          <Rating rating={review.rating} showRatingValue={false} />
          <Heading type={5}>{`${review.user.first_name} ${review.user.last_name}`}</Heading>
        </div>
      </div>
    )
  })

  return(
    <Col xs={24} sm={24} md={12} lg={8} xl={8} key={superStage.id}>
      <div className="super-stage-container">
        <div
          className="super-stage"
          role="link"
          tabIndex={0}
        >
          <div className="thumb-container">
            <img src={superStage.image.url} alt="Super stage thumbnail" />
          </div>

          <div className="content">
            <h4>{superStage.title}</h4>
            <Rating rating={5} showRatingValue={false}  className="rating"/>
            
            <div className="summary">
              { superStage.summery }
            </div>

            <Row justify="space-between" className="btns-container" gutter={20}>
              <Col xs={24} sm={12}>
                {
                  reviewsList && reviewsList.length > 0 && (
                    <Button
                      type={'default'}
                      size="large"
                      fullWidth={true}
                      onClick={() => { setShowReviews(!showReviews) }}
                      children={<small>Reviews</small>}
                    />
                  )
                }
              </Col>

              <Col xs={24} sm={12}>
                <Button 
                  fullWidth={true}
                  size="large"
                  onClick={() => navigateToSuperStage()}
                >
                  Explore
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {
        showReviews && reviewsList && reviewsList.length > 0 && (
          <Modal open={showReviews} setOpen={ () => setShowReviews(!showReviews) } size="small" title={'Student Reviews'}>
            <div className="reviews-panel">
              <div className="reviews-container">
                {reviewsList}
              </div>
            </div>
          </Modal>
        )
      }
    </Col>
  )
}

export default SuperStageMini;