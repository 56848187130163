import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Header as CourseHeader, PurchaseBtn } from 'components/common/course';
import Container from 'components/common/section-container';
import SubjectMini from 'components/common/course/subject-mini';
import RoutesGenerator, { Route } from 'services/router/generator';
import './styles.less';

const CourseStage = (props: any) => {
  const { stage, courseId, courseTitle, skipSuperStage } = props;
  const history = useHistory();
  const [isPurchased, setIsPurchased] = useState<boolean>(false);
  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const { cid, id } = useParams<any>();

  const subjects = stage.subjects.map((subject: any) => (
    <SubjectMini
      key={subject.id}
      subject={subject}
      actionBtn={{
        label: 'Explore',
        goTo: (subjectId: number) => {
          let path = RoutesGenerator(
            Route.Subject, { 
              courseId: courseId ? courseId : cid,
              superStageId: id, subjectId: subjectId,
              skipSuperStage: skipSuperStage
            }
          )
          history.push(path)
        },
      }}
    />
  ));

  const checkAtLeastOneSubjectIsPurchased = () => {
    stage.subjects.forEach((subject : any) => {
      if(subject.is_purchased) {
        if(subject.purchased_exam_badge){
          setIsPurchased(true)
        }
        if (subject.sub_order_cancel) {
          setIsCancelled(true)
        }
      }
    });

    // console.log("isCancelled:", isCancelled)
    // console.log("isPurchased:", isPurchased)
    // console.log("stage.skip_stage:", stage.skip_stage)
  }

  const buy = async (stageId: number) => {
    history.push(
      RoutesGenerator(Route.PaymentOptions, { type: 'stage', id: stageId }),
    );
  };

  useEffect(() => {
    // check at least one subject is purchased by user
    checkAtLeastOneSubjectIsPurchased();
  }, [])


  return (
    <Container className="stage-container">
      <CourseHeader
        courseTitle={courseTitle}
        title={stage.title}
        price={stage.original_price}
        discountedPrice={stage.discounted_price}
        hasPurchased={stage.is_purchased && stage.purchased_exam_badge != null}
        easyPayAvailable={stage.easy_pay_available}
        isOneSubjectPurchased={((isPurchased  && isCancelled == false) || (stage.stage_order_cancel == false && stage.is_purchased) || stage.skip_stage )}
        actionBtn={
          <PurchaseBtn
            label="BUY THIS STAGE"
            id={stage.id}
            hasPurchased={(stage.is_purchased && stage.stage_order_cancel == false) && stage.purchased_exam_badge != null}
            purchase={buy}
            type="stage"
          />
        }
      />
      {subjects}
    </Container>
  );
};

export default CourseStage;
